import React, { Suspense, useEffect } from "react"

import { isSSR } from "../utils/constants"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import Spinner from "../components/Spinner"
import { actualCountry } from "../utils/constants"
import { navigate } from "gatsby"

const CreditEvaluation = React.lazy(() =>
  import("../components/CreditEvaluation")
)

export default () => {
  useEffect(() => {
    if (actualCountry !== "mx") {
      navigate("/")
    }
  }, [])
  return (
    <>
      {!isSSR && (
        <Suspense fallback={<Spinner />}>
          <SEO
            title={"Evaluación de crédito"}
            keywords={["Evaluación", "crédito"]}
            description={
              "Inicia tu evaluación de Crédito Llena el registro y obtén tu pre-aprobación"
            }
          />
          <Layout>
            <CreditEvaluation />
          </Layout>
        </Suspense>
      )}
    </>
  )
}
